<template>
  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <sub-title>충전</sub-title>

        <div class="main_panel">
          <div class="re_info">
            <div>
              <h3>이벤트보너스</h3>
              <div>(신규/돌발/복귀 이벤트 참여시)</div>
              <p>- 스포츠1.4배당 이하(포함 폴더수 인정x)</p>
              <p>- 스포츠 다폴더 100%</p>
              <p>- 스포츠 두폴더/단폴더 배팅불가</p>
              <p>- 미니게임 500%(토큰 HI/LO 만 배팅시 롤링인정x)</p>
              <p>- 인플레이 이용불가(적발시 몰수처리)</p>
              <p>- 카지노/슬롯 이용불가(적발시 몰수처리)</p>
            </div>

            <div>
              <h3>스포츠보너스</h3>
              <div>(첫,매충 20%)</div>
              <p>- 스포츠1.4배당 이하(포함 폴더수 인정x)</p>
              <p>- 스포츠 다폴더 100%</p>
              <p>- 스포츠 두폴더/단폴더 배팅불가</p>
              <p>- 미니게임 500%(토큰 HI/LO 만 배팅시 롤링인정x)</p>
              <p>- 인플레이 이용불가(적발시 몰수처리)</p>
              <p>- 카지노/슬롯 이용불가(적발시 몰수처리)</p>
            </div>

            <div>
              <h3>미니게임보너스</h3>
              <div>(첫,매충 10%)</div>
              <p>- 스포츠1.4배당 이하(포함 폴더수 인정x)</p>
              <p>- 스포츠 두폴더 100%</p>
              <p>- 단폴더 배팅불가</p>
              <p>- 미니게임 200%(토큰 HI/LO 만 배팅시 롤링인정x)</p>
              <p>- 인플레이 이용불가(적발시 몰수처리)</p>
              <p>- 카지노/슬롯 이용불가(적발시 몰수처리)</p>
            </div>

            <div>
              <h3>카지노보너스</h3>
              <div>(첫,매충 5%)</div>
              <p>- 스포츠1.4배당 이하(포함 폴더수 인정x)</p>
              <p>- 스포츠 단폴더 100%</p>
              <p>- 미니게임 200%(토큰 HI/LO 만 배팅시 롤링인정x)</p>
              <p>- 인플레이 이용불가(적발시 몰수처리)</p>
              <p>- 카지노 200%</p>
              <p>- 슬롯 200%</p>
            </div>
            <div>
              <h3>보너스 미적용</h3>
              <div>- 모든종목 무제재 100%</div>
            </div>

          </div>


          <div class="re_cont">
            <div class="c1">
              입금명
            </div>
            <div class="c2" style="">
              <span>{{ $store.state.userInfo.beneficiary }}</span>
            </div>
          </div>
          <div class="re_cont">
            <div class="c1">
              금액
            </div>
            <div class="c2" style="">
              <div style="display: flex;justify-content: flex-start;align-items: center;padding: 10px 0">
                <input type="text" v-model="applyCash.amount" class="re_input" placeholder="충전금액"
                       style="">
                <button type="button" class="btn-re" @click="changeMoney(0)"
                        style="width: 130px;margin-left: 6px">정정하기
                </button>
              </div>
              <div style="display: flex; justify-content: flex-start;width: 100%;padding: 10px 0;flex-wrap: wrap">
                <button type="button" class="btn-re" @click="changeMoney(10000)">
                  1만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(30000)">
                  3만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(50000)">
                  5만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(100000)">
                  10만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(500000)">
                  50만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(1000000)">
                  100만원
                </button>

              </div>
            </div>
          </div>

          <div class="re_cont">
            <div class="c1">
              보너스 동의
            </div>
            <div class="c2" style="font-weight: normal;">
              <div style="padding: 10px 0">
                <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">
                  <input type="radio" name="bonusin" id="bonusin4"
                         :value="sportsConst.APPLY_BONUS_YES4"
                         v-model="applyCash.bonusin">
                  <span style="margin-left: 5px"><label for="bonusin4" style="color: #fff60d"> 이벤트 보너스</label></span>

                </div>
                <div style="padding-left: 16px;color: #7ac7e2;">
                  <div>(신규/돌발/복귀 이벤트 참여시)</div>
                  <div>("포인트롤링 규정"필독)</div>
                </div>
              </div>
              <div style="padding: 10px 0">
                <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">
                  <input type="radio" name="bonusin" id="bonusin1"
                         :value="sportsConst.APPLY_BONUS_YES1"
                         v-model="applyCash.bonusin">
                  <span style="margin-left: 5px"><label for="bonusin1" style="color: #fff60d"> 스포츠 보너스</label></span>

                </div>
                <div style="padding-left: 16px;color: deepskyblue;">
                  <div>(첫/매충 20%지급)</div>
                  <div>("포인트롤링 규정"필독)</div>
                </div>
              </div>
              <div style="padding: 10px 0">
                <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">
                  <input type="radio" name="bonusin" id="bonusin2"
                         :value="sportsConst.APPLY_BONUS_YES2"
                         v-model="applyCash.bonusin">
                  <span style="margin-left: 5px"><label for="bonusin2" style="color: skyblue"> 미니게임 보너스</label></span>
                </div>
                <div style="padding-left: 16px;color: #fd7879;">
                  <div>(첫/매충 10%지급)</div>
                  <div>("포인트롤링 규정"필독)</div>

                </div>
              </div>
              <div style="padding: 10px 0">
                <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">
                  <input type="radio" name="bonusin" id="bonusin3"
                         :value="sportsConst.APPLY_BONUS_YES3"
                         v-model="applyCash.bonusin">
                  <!--                                    <span style="margin-left: 5px"><label for="bonusin4" style="color: skyblue"> 카지노 보너스</label></span>-->
                  <span style="margin-left: 5px"><label for="bonusin3" style="color: skyblue"> 카지노 보너스</label></span>
                </div>
                <div style="padding-left: 16px;color: #fd7879;">
                  <div>(첫/매충 5%지급 + <font color="#ffd700">콤프적용</font>)</div>
                  <div>("포인트롤링 규정"필독)</div>

                </div>
              </div>
              <div style="padding: 10px 0">
                <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">
                  <input type="radio" name="bonusin" id="bonusinnot"
                         :value="sportsConst.APPLY_BONUS_NOT"
                         v-model="applyCash.bonusin">
                  <span style="margin-left: 5px"><label for="bonusinnot" style="color: red"> 무재제 보너스</label></span>
                </div>
                <div style="padding-left: 16px;color: #fd7879;">
                  (첫/매충 미지급)
                </div>
                <div style="padding-left: 16px;color: #fd7879;">
                  ( <font color="#ffd700">콤프적용</font>)
                </div>
              </div>
            </div>
          </div>
          <div class="rec_buttons" style="">

          </div>
          <div class="rec_buttons" style="">
            <button class="b3" @click="goCoinSite()" v-if="$store.state.userInfo.coinrechargeable == sportsConst.YES">[코인 바로이동]
            </button>
            <button class="b1" @click="recharge(0)">입금신청
            </button>

            <button class="b2" @click="questioAcNo" v-if="$store.state.userInfo.coinrechargeable == sportsConst.NOT">계좌문의
            </button>
          </div>
        </div>
        <div class="re_history">
          <table cellpadding="0" cellspacing="0" border="0">
            <colgroup>
              <col width="27%">
              <col width="27%">
              <col width="27%">
              <col width="15%">
            </colgroup>
            <thead>
            <tr>
              <th>일자</th>
              <th>금액</th>
              <th @click="refreshData()" style="cursor: pointer">상태 <i class="fa fa-refresh"
                                                                       aria-hidden="true"></i>
              </th>
              <th>-</th>
            </tr>
            <tr v-for="(item,indx) in rechargeList" :key="indx">
              <td>{{ item.createTime|datef('MM/DD HH:mm') }}</td>
              <td>{{ item.amount|comma }}</td>
              <td>
                <span v-if="item.status === sportsConst.APPLY_STATUS_WAITING">미확인</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_CHECKING">확인중</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_COMPLITE"><i
                    class="fa fa-check"
                    aria-hidden="true"></i></span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_CANCEL"
                      style="color: red">실패</span>
              </td>
              <td>
                  <span
                      style="display: inline-block;width: 60px; height: 20px; line-height: 20px;text-align: center;cursor: pointer"
                      v-if="item.status !== sportsConst.APPLY_STATUS_WAITING && item.status
                        !== sportsConst.APPLY_STATUS_CHECKING" @click="deleteById(item.id)">
                     <i class="fa fa-close"></i> 삭제
                  </span>

              </td>
            </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <!-- right 메뉴 -->
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <!--배팅카트-->
      <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>

    <foot-comp></foot-comp>

  </div>

</template>

<script>
import SubTitle from "../../components/SubTitle";
import RightBarComp from "../../components/RightBarComp";
import {deleteApply, getApplyCashList, getChargeUrl, rechargeCash, saveQuestion} from "../../network/userRequest";
import sportsConst from "../../common/sportsConst";
import LeftBarComp from "../../components/LeftBarComp";
import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
import FootComp from "../../components/FootComp";
import TopbarComp from "../../components/TopbarComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import {addMemberCasinoCash, getAccountBalance} from "../../network/casinoHonorRequest";
import {
  RECEIVE_HIDE_LOADING,
  RECEIVE_SHOW_LOADING,
  RECEIVE_SPORTS_CART_ITEM_DEL_ALL
} from "../../store/mutation-types";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import {postionMixin} from "../../common/mixin";
import UserInfoComp from "../../components/UserInfoComp";
import RightButtonsComp from "../../components/RightButtonsComp";
import SportsBetCartComp from "../../components/sports/SportsBetCartComp";

export default {
  name: "Recharge",
  mixins: [postionMixin],
  components: {
    SportsBetCartComp,
    RightButtonsComp,
    UserInfoComp,
    SportsLeftBarComp,
    RightBarBannerComp, TopbarComp, FootComp, LeisureLeftComp, LeftBarComp, RightBarComp, SubTitle
  },
  data() {
    return {
      sportsConst,
      applyCash: {
        amount: 0,
        bonusin: sportsConst.APPLY_BONUS_YES1,
        consiterecharge: 0,
      },
      search: {applyType: sportsConst.APPLY_TYPE_RECHARGE},
      rechargeList: [],
      isSampleSite: false,
      casinocash: 0,
      position: "입금",
      b1: 0,
      b2: 0,
      b3: 0,
    }
  },
  methods: {
    goCoinSite() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getChargeUrl().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          let curl = res.data.data;
          window.open(curl, '_blank')
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    changeMoney(cash) {
      if (cash === 0) {
        this.applyCash.amount = 0;
      } else {
        this.applyCash.amount += cash;
      }
    },
    changeCasinoMoney(cash) {
      if (cash === 0) {
        this.casinocash = 0;
      } else {
        this.casinocash += parseInt(cash);
      }
    },
    gotocoinsite() {
      window.open(sportsConst.APPLY_COINSITE_URL, '_blank')
    },
    recharge(consiterecharge) {
      this.applyCash.consiterecharge = consiterecharge;
      if (this.applyCash.bonusin === -1) {
        this.$swal({
          title: '보너스 동의를 선택하세요',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false
      }

      this.$swal({
        title: this.applyCash.amount + '원 충전신청 하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          rechargeCash(this.applyCash).then(res => {
            if (res.data.success) {
              this.$swal({
                title: '충전신청성공',
                type: 'success',
                showCancelButton: false,
                showConfirmButton: true
              })
              this.applyCash.bonusin = 1
              this.applyCash.amount = 0
              this.applyCash.consiterecharge = 0;
              this.initRechargeList()
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
            this.$store.commit(RECEIVE_HIDE_LOADING)
          })
        }
      })
    },
    change2casinocash() {
      if (this.casinocash > this.$store.state.userInfo.cash) {
        this.$swal({
          title: '보유금액 ' + this.$store.state.userInfo.cash + '원을 초과하였습니다',
          type: 'error',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      this.$store.commit(RECEIVE_SHOW_LOADING)
      addMemberCasinoCash(this.casinocash).then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          this.$swal({
            title: '전환성공',
            type: 'success',
            showCancelButton: false,
            showConfirmButton: true
          })
          this.casinocash = 0;
          this.$store.dispatch('actionUserInfo')
          setTimeout(() => {
            this.getCasinoBalance();
          }, 1300)

        } else {
          this.$swal({
            title: '전환실패, ' + res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }

      })
    },
    initRechargeList() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getApplyCashList(this.search).then(res => {
        if (res.data.success) {
          this.rechargeList = res.data.data
        }
        this.$store.commit(RECEIVE_HIDE_LOADING)
      })
    },
    refreshData() {
      this.initRechargeList()
      this.$bus.$emit('applyRequestSuccess')
    },
    deleteById(id) {
      this.$swal({
        title: '삭제하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          deleteApply(id).then(res => {
            if (res.data.success) {
              this.initRechargeList()
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    getCasinoBalance() {
      getAccountBalance().then(res => {
        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
      })
    },
    questioAcNo() {

      this.$swal({
        title: '고객센터로 계좌문의를 하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          let content = '계좌번호 문의합니다'
          saveQuestion({'content': content, 'type': sportsConst.CUSTOMER_TYPE_BANK_CARD}).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.content = ''
              this.$swal({
                title: '계좌번호 문의글 작성이 완료되였습니다',
                type: 'success',
                showCancelButton: false,
                showConfirmButton: true
              })
              this.$router.push('/customer_center')
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })


    },
    setRechargeBonus() {
      try {
        let rb = this.$store.state.userInfo.rank.rechargebonus1.split(",");
        this.b1 = rb[0]
        this.b2 = rb[1]
        this.b3 = rb[2]
      } catch (e) {
        console.log(e)
      }

    },
  },
  created() {
    this.initRechargeList();
    this.setRechargeBonus()

  },
  mounted() {

  }
}
</script>

<style scoped>
@import url("../../assets/css/reex.css");
</style>